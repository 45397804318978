import React from 'react';

import styled from 'styled-components';

import { graphql, useStaticQuery } from 'gatsby';

import _ImageGallery from '../../components/ImageGallery/ImageGallery';
import Layout from '../../components/layout';

const ImageGallery = styled(_ImageGallery)`
    margin-top: 30px;
    margin-bottom: 60px;
`;

const query = graphql`
    {
        allFile(
            filter: { relativeDirectory: { eq: "gallery/dance" } }
            sort: { fields: [birthTime], order: DESC }
        ) {
            edges {
                node {
                    dir
                    modifiedTime
                    childImageSharp {
                        fluid(maxWidth: 1000) {
                            src
                            aspectRatio
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;

const GalleryPage = ({ location }) => {
    const data = useStaticQuery(query);
    const pictures = data.allFile.edges.map(
        edge => edge.node.childImageSharp.fluid
    );

    return (
        <Layout location={location}>
            <h1 className="major">Danse</h1>
            Etant moi-même danseur, cet art représente une part importante de ma
            vie. Cela m'aide à anticiper les moments décisifs à photographier où
            le danseur ouvre une faille pour laisser passer l'émotion. Habitué
            des festivals de danse, je réalise des reportages sur un ou
            plusieurs jours.
            <ImageGallery pictures={pictures} />
        </Layout>
    );
};

export default GalleryPage;
